
// Import all of Bootstrap's JS
import * as bootstrap from '../../node_modules/bootstrap/'

import './contrib.js'



// Toggle filters on main listing page on mobile
jQuery( document ).ready( function( $ ) {


	$('#accordion_filters_btn').on('click', function(e){

		var $box = $('#accordion_filters');
		if ($box.hasClass('d-none')){
			$box.removeClass("d-none");
		}
		else {
			$box.addClass("d-none");
		}
		np
	});


	// Trigger focus on search input
	setTimeout(function(){
		console.log('Attempting to focus...');
		$('.facetwp-search').trigger('focus');
	}, 220)


});


jQuery( window ).on('load', function( ) { 

	let $content = jQuery("#content");
	let bgimg = $content.css('background-image');

	if($content.attr('data-bg-img') != "" && bgimg=='none' ){
		$content.css("background-image", "url("+$content.attr('data-bg-img')+")" );
	}

});


// Dropdown on hover

document.addEventListener("DOMContentLoaded", function(){
// make it as accordion for smaller screens
if (window.innerWidth > 992) {

	document.querySelectorAll('.navbar .nav-item').forEach(function(everyitem){

		everyitem.addEventListener('mouseover', function(e){

			let el_link = this.querySelector('a[data-bs-toggle]');

			if(el_link != null){
				let nextEl = el_link.nextElementSibling;
				el_link.classList.add('show');
				nextEl.classList.add('show');
        nextEl.setAttribute('data-bs-popper', 'static');
			}

		});
		everyitem.addEventListener('mouseleave', function(e){
			let el_link = this.querySelector('a[data-bs-toggle]');

			if(el_link != null){
				let nextEl = el_link.nextElementSibling;
				el_link.classList.remove('show');
				nextEl.classList.remove('show');
			}


		})
	});

}
// end if innerWidth
});
// DOMContentLoaded  end



	
jQuery( document ).ready( function( $ ) {


    $('a.acf-gallery-add').text('Ajouter des fichiers annexes');
	

	
	
});
